<i18n>
{
  "de": {
    "pageTitle": "Energieträger & Emissionsfaktoren",
    "tableTitle": {
      "electricityProducts": "Stromprodukte",
      "otherEnergyCarriers": "Energieträger"
    },
    "settings": "Einstellungen",
    "pageDescription": "Erlaubt das definieren der im Portfolio standardmässig geltenden Emissionsfaktoren und Nutzungen der Energieträger.",
    "updateParamsPendingMessage": "Parameter werden gespeichert...",
    "viewReidaButton": "REIDA Emissionsfaktoren anzeigen",
    "addButton": "Energieträger hinzufügen",
    "addUsageButton": "Nutzung hinzufügen",
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "returnButton": "Zurück",
    "noRecords": "Für dieses Portfolio sind noch keine Energieträger vorhanden",
    "pendingMessage": "Energieträger werden neu geladen, bitte warten...",
    "editColumnsButton": "Spalten verwalten",
    "editTableButton": "Tabelle bearbeiten",
    "addYearButton": "Jahr hinzufügen",
    "editYearsButton": "Jahre verwalten",
    "initialYear": "Initial"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady">
    <!-- Page title -->
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>
    </template>

    <template #default>
      <p>{{ $t('pageDescription') }}</p>

      <!-- Errors -->
      <div v-if="error">
        {{ error }}
      </div>
      <div v-if="pending">
        {{ $t('pendingMessage') }}
      </div>

      <!-- Table Controls Container -->
      <div class="controls">
        <!-- Left corner: Years -->
        <div class="controls__years">
          <div v-if="sustainabilityYears" class="years">
            <div v-for="(year, index) in sustainabilityYears" :key="index" class="year">
              <button
                v-if="year.year === 0"
                type="button"
                :class="{ active: year.year === yearActive }"
                @click="onYearClicked(0)"
              >
                {{ $t('initialYear') }}
              </button>
              <button
                v-else
                type="button"
                :class="{ active: year.year === yearActive }"
                @click="onYearClicked(year.year)"
              >
                {{ year.year }}
              </button>
            </div>
          </div>
          <!-- Add year -->
          <ButtonWrapper v-if="getPortfolioPermission('EDIT_ENERGY_CARRIERS')">
            <Button
              v-if="sustainabilityYears.length === 1"
              icon="plus"
              :text="$t('addYearButton')"
              @click="onEditYearsModalOpen()"
            />
            <Button v-else :text="$t('editYearsButton')" @click="onEditYearsModalOpen()" />
          </ButtonWrapper>
        </div>

        <!-- Right corner: Buttons -->
        <div class="controls__buttons">
          <ButtonWrapper>
            <!-- View Reida Energy Carriers -->
            <Button :text="$t('viewReidaButton')" @click="onReidaEnergyCarriersModalOpen()" />
            <!-- Add Energy Carrier -->
            <Button
              v-if="getPortfolioPermission('EDIT_ENERGY_CARRIERS')"
              icon="plus"
              :text="$t('addButton')"
              @click="onEditModalOpen(null)"
            />
            <!-- Add Energy Carrier Usage -->
            <Button
              v-if="getPortfolioPermission('EDIT_SUSTAINABILITY_OVERVIEW')"
              icon="plus"
              :text="$t('addUsageButton')"
              @click="onEditUsageModalOpen(null, null)"
            />
            <!-- Toggle button: show/hide table columns -->
            <ListExpandToggle v-model="isTableExtended" :size="20" />
          </ButtonWrapper>
        </div>
      </div>

      <EnergyCarriersTable
        v-if="sortedEnergyCarriers.filter((ec) => ec.electricity_grid === true)"
        :title="$t('tableTitle.electricityProducts')"
        :is-table-extended="isTableExtended"
        :sorted-energy-carriers="sortedEnergyCarriers.filter((ec) => ec.electricity_grid === true)"
        :sorted-energy-carrier-usages="sortedEnergyCarrierUsages"
        :reida-options="reidaOptions"
        @edit-energy-carrier="onEditModalOpen"
        @edit-usage="onEditUsageModalOpen"
      />

      <EnergyCarriersTable
        v-if="sortedEnergyCarriers.filter((ec) => ec.electricity_grid === false)"
        :title="$t('tableTitle.otherEnergyCarriers')"
        :is-table-extended="isTableExtended"
        :sorted-energy-carriers="sortedEnergyCarriers.filter((ec) => ec.electricity_grid === false)"
        :sorted-energy-carrier-usages="sortedEnergyCarrierUsages"
        :reida-options="reidaOptions"
        @edit-energy-carrier="onEditModalOpen"
        @edit-usage="onEditUsageModalOpen"
      />

      <ReidaEnergyCarriersModal
        v-if="reidaEnergyCarriersModalOpen"
        :reida-energy-carriers="reidaEnergyCarriers"
        @close="onReidaEnergyCarriersModalClose()"
      />

      <EditEnergyCarrierModal
        v-if="editModalOpen"
        :portfolio="portfolio"
        :energy-carrier="editEnergyCarrier"
        :reida-options="reidaOptions"
        @close="onEditModalClose()"
      />

      <EditSustainabilityUsageModal
        v-if="editUsageModalOpen"
        :portfolio="portfolio"
        :all-energy-carriers="sortedEnergyCarriers"
        :energy-carrier="editEnergyCarrier"
        :all-factors="sortedEnergyCarrierUsages"
        :factors="editEnergyCarrierFactors"
        :year="editEnergyCarrierYear"
        @close="onEditUsageModalClose()"
      />

      <EditSustainabilityYearsModal
        v-if="editYearsModalOpen"
        :portfolio="portfolio"
        :sustainability-years="sustainabilityYears"
        @close="onEditYearsModalClose()"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import EditSustainabilityUsageModal from '@/components/settings/energy_carriers/EditSustainabilityUsageModal.vue'
import EditEnergyCarrierModal from '@/components/settings/energy_carriers/EditEnergyCarrierModal.vue'
import EditSustainabilityYearsModal from '@/components/settings/energy_carriers/EditSustainabilityYearsModal.vue'
import SustainabilityYearsMixin from '@/pages/vuex-mixins/SustainabilityYearMixin.vue'
import SustainabilityIndicatorFactorsMixin from '@/pages/vuex-mixins/SustainabilityIndicatorFactorsMixin.vue'
import ListExpandToggle from '@/components/shared/lists/ListExpandToggle.vue'
import ReidaEnergyCarriersModal from '@/components/settings/energy_carriers/ReidaEnergyCarriersModal.vue'
import PageTitle from '@/components/shared/PageTitle.vue'
import EnergyCarriersTable from '@/pages/settings/EnergyCarriersTable.vue'
// import FilterableListColumnHeader from '@/components/shared/lists/FilterableListColumnHeader.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  name: 'settingsEnergyCarriers',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady, energyCarrierProfiles
    SustainabilityYearsMixin, // Provides: sustainabilityYears
    SustainabilityIndicatorFactorsMixin, // Provides: sustainabilityIndicatorFactors
  ],

  components: {
    EnergyCarriersTable,
    // FilterableListColumnHeader,
    ListExpandToggle,
    ButtonWrapper,
    EditSustainabilityUsageModal,
    EditEnergyCarrierModal,
    EditSustainabilityYearsModal,
    ReidaEnergyCarriersModal,
    MainLayout,
    PageTitle,
    Button,
  },

  data() {
    return {
      editEnergyCarrier: null,
      editEnergyCarrierFactors: null,
      // Modals
      editModalOpen: false,
      editYearsModalOpen: false,
      editUsageModalOpen: false,
      reidaEnergyCarriersModalOpen: false,
      // Helpers
      error: null,
      pending: false,
      isTableExtended: false,
      // Year 0 is initial year
      yearIdActive: 0,
      yearActive: 0,
      // usageOrderTemplate: ['STANDARD', 'TENANTS', 'FEED_IN', 'ROOM_HEAT', 'HOT_WATER'], // Complete
      usageOrderTemplate: ['STANDARD', 'TENANTS', 'FEED_IN'], // Currently supported
      usageFilter: [
        {
          label: this.$t(`usages.STANDARD`),
          value: 'STANDARD',
          show: true,
        },
        {
          label: this.$t(`usages.TENANTS`),
          value: 'TENANTS',
          show: true,
        },
        {
          label: this.$t(`usages.FEED_IN`),
          value: 'FEED_IN',
          show: true,
        },
      ],
    }
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers']),

    ...mapGetters({
      __reidaEnergyCarriers: 'portfolio/getReidaEnergyCarriers',
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    //
    reidaOptions() {
      return this.__reidaEnergyCarriers().map((obj) => {
        return { id: obj['id'], name: obj['name'] }
      })
    },

    //
    reidaEnergyCarriers() {
      return this.__reidaEnergyCarriers()
    },

    //
    sortedEnergyCarriers() {
      if (this.portfolio.energy_carriers) {
        const tmpList = [
          ...this.portfolio.energy_carriers.filter(
            (v) => !['ELECTRICITY_DYNAMIC', 'ELECTRICITY_DYNAMIC_WITH_PV'].includes(v.name)
          ),
          // More filters could be applied here
          // ...
        ]
        // Electricity grids first
        tmpList.sort((a, b) => {
          return Number(b.electricity_grid) - Number(a.electricity_grid)
        })
        // Default energy carriers first
        tmpList.sort((a, b) => {
          return Number(b.default) - Number(a.default)
        })
        return tmpList
      }
      return []
    },

    // List of energy carriers with sustainability indicator factors and usage
    // sortedAndFilteredEnergyCarrierUsages() {
    sortedEnergyCarrierUsages() {
      if (this.sustainabilityIndicatorFactorsLoaded && this.sustainabilityIndicatorFactors) {
        const tmpList = [
          ...this.sustainabilityIndicatorFactors.entries.filter(
            (v) => !['ELECTRICITY_DYNAMIC', 'ELECTRICITY_DYNAMIC_WITH_PV'].includes(v.ec_name)
          ),
          // More filters could be applied here
          // Example:
          // .filter((v) =>
          //   this.usageFilter
          //     .filter((u) => u.show)
          //     .map((u) => u.value)
          //     .includes(v.usage)
          // ),
        ]
        // Apply usage sort order
        tmpList.sort((a, b) => {
          return this.usageOrderTemplate.indexOf(a.usage) - this.usageOrderTemplate.indexOf(b.usage)
        })
        return tmpList
      }
      return []
    },

    //
    editEnergyCarrierYear() {
      const year = this.sustainabilityYears.find((ecy) => ecy.year === this.yearActive)
      return year || null
    },

    // not used?
    // Object with all energy carriers, no filters applied, no sustainability indicators
    // energyCarrierMap() {
    //   return this.portfolio.energy_carriers.reduce((acc, v) => {
    //     acc[v.name] = v
    //     return acc
    //   }, {})
    // },
  },

  watch: {
    sustainabilityYears() {
      if (this.sustainabilityYears) {
        const year = this.sustainabilityYears.find((ecy) => ecy.year === this.yearActive)
        if (year) {
          this.yearIdActive = year.id
        }
      }
    },

    yearActive() {
      if (this.sustainabilityYears) {
        const year = this.sustainabilityYears.find((ecy) => ecy.year === this.yearActive)
        if (year) {
          this.yearIdActive = year.id
        }
      }
    },
  },

  methods: {
    //
    onEditModalOpen(energyCarrier) {
      if (energyCarrier) {
        this.editEnergyCarrier = energyCarrier
      } else {
        this.editEnergyCarrier = null
      }
      this.editModalOpen = true
    },

    //
    onEditModalClose() {
      this.editModalOpen = false
    },

    //
    onEditYearsModalOpen() {
      this.editYearsModalOpen = true
    },

    //
    onEditYearsModalClose() {
      this.editYearsModalOpen = false
    },

    //
    onEditUsageModalOpen(energyCarrier, factors) {
      this.editEnergyCarrier = energyCarrier || null
      this.editEnergyCarrierFactors = factors || null
      this.editUsageModalOpen = true
    },

    //
    onEditUsageModalClose() {
      this.editUsageModalOpen = false
    },

    //
    onYearClicked(year) {
      this.yearActive = year
    },

    //
    onReidaEnergyCarriersModalOpen() {
      this.reidaEnergyCarriersModalOpen = true
    },

    //
    onReidaEnergyCarriersModalClose() {
      this.reidaEnergyCarriersModalOpen = false
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.energy-carriers-table {
  .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  margin-bottom: 40px;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & .controls__years,
  & .controls__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  & .controls__buttons {
    justify-content: flex-end;
    align-items: stretch;
  }
}

.years {
  display: flex;
  flex-direction: row;
  padding: 8px;
  flex-wrap: wrap;

  .year {
    button {
      background: none;
      border: none;
      font-size: var(--font-s);

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: rgba(0, 0, 0, 1);
        border-bottom: 2px solid #000;
      }

      margin: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.factor {
  position: relative;
  transition: margin-bottom 0.2s ease-in-out;

  & .source-anchor {
    display: block;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &:has(> .source-anchor) {
      margin-bottom: 12px;
    }

    & .source-anchor {
      opacity: 1;
    }
  }
}

.source-anchor {
  position: absolute;
  right: 0;
}

.source-container {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
  display: flex;
  justify-content: flex-end;
}
</style>
