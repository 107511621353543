<i18n>
{
  "de": {
    "pageTitle": "Allgemein",
    "settings": "Einstellungen"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>
    </template>

    <template #default>
      <EditGeneral v-if="portfolio.targets" :portfolio="portfolio" class="targets-list" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import EditGeneral from '@/components/settings/EditGeneral.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'settingsGeneral',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    MainLayout,
    EditGeneral,
    PageTitle,
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
